<template>
  <div>
    <!-- 检索 -->
    <el-form v-if="isForm" :inline="true" :model="formT" ref="ruleFormT">
      <el-form-item class="form-item" label="文本" prop="keyword">
        <el-input
          v-model="formT.keyword"
          placeholder="请输入英文或者中文"
          @keyup.enter="handSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="上线时间" prop="onlinetime">
        <el-date-picker
          v-model="onlinetime"
          type="date"
          :editable="false"
          placeholder="选择上线日期"
          @change="handOnlineTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="form-item" label="最近编辑时间" prop="updated_at">
        <el-date-picker
          v-model="updated_at"
          type="datetime"
          :editable="false"
          placeholder="选择编辑时间"
          @change="handUpdate"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="form-item" label="课程级别" prop="level_id">
        <el-select
          v-model="formT.level_id"
          placeholder="全部"
          @change="handProgram"
        >
          <el-option label="U1-L1" value="1"></el-option>
          <el-option label="U2-L4" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="课程主题" prop="program_id">
        <el-select v-model="formT.program_id" placeholder="全部" clearable>
          <el-option
            v-for="(item, index) in programList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="审核状态" prop="status">
        <el-select v-model="formT.status" placeholder="全部" clearable>
          <el-option label="待审核" value="0"></el-option>
          <el-option label="审核不通过" value="1"></el-option>
          <el-option label="审核通过" value="2"></el-option>
          <el-option label="已发布" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="是否备选" prop="is_alternative">
        <el-select v-model="formT.is_alternative" placeholder="全部" clearable>
          <el-option label="是" value="1"></el-option>
          <el-option label="否" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item form-submit">
        <el-button type="primary" @click="handSubmit">查 询</el-button>
        <el-button @click="handResetForm('ruleFormT')">重 置</el-button>
      </el-form-item>
    </el-form>
    <el-form v-else :inline="true" :model="form" ref="ruleForm">
      <el-form-item class="form-item" label="文本" prop="keyword">
        <el-input
          v-model="form.keyword"
          placeholder="请输入英文或者中文"
          @keyup.enter="onSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="最近编辑时间" prop="updated_at">
        <el-date-picker
          v-model="info_updated_at"
          type="datetime"
          :editable="false"
          placeholder="选择编辑时间"
          @change="handInfoUpdate"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="form-item" label="课程级别" prop="level_id">
        <el-select v-model="form.level_id" placeholder="全部">
          <el-option label="U1-L1" value="1"></el-option>
          <el-option label="U2-L4" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="课程主题" prop="program_id">
        <el-select
          v-model="form.program_id"
          placeholder="全部"
          @focus="handPrograms"
        >
          <el-option
            v-for="(item, idx) in programLists"
            :key="idx"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="审核状态" prop="status">
        <el-select v-model="form.status" placeholder="全部">
          <el-option label="待审核" value="0"></el-option>
          <el-option label="审核通过" value="1"></el-option>
          <el-option label="审核不通过" value="2"></el-option>
          <el-option label="已发布" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="是否备选" prop="is_alternative">
        <el-select v-model="form.is_alternative" placeholder="全部">
          <el-option label="是" value="1"></el-option>
          <el-option label="否" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item form-submit">
        <el-button type="primary" @click="onSubmit">查 询</el-button>
        <el-button @click="resetForm('ruleForm')">重 置</el-button>
      </el-form-item>
    </el-form>
    <!-- 检索 -->
    <div class="table-content table-follow">
      <el-button
        v-if="!isForm"
        class="follow-add"
        type="primary"
        @click="goFollow"
        >创建任务</el-button
      >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="跟读任务列表" name="1">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="loading"
          >
            <el-table-column prop="id" label="ID" width="50" align="center">
            </el-table-column>
            <el-table-column
              prop="sentence"
              label="跟读英文"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="sentence_name"
              label="跟读中文"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="image"
              label="配图"
              width="150"
              align="center"
            >
              <template #default="scope">
                <el-image
                  style="width: 100px"
                  :src="scope.row.image"
                  fit="contain"
                >
                  <template #placeholder>
                    <div class="image-slot">
                      <i class="el-icon-loading" :size="32"></i>
                      <div>加载中<span class="dot">...</span></div>
                    </div>
                  </template>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="level_name"
              label="课程级别"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="program_name"
              label="课程主题"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="status"
              label="状态"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="updated_at"
              label="编辑时间"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="onlinetime"
              label="上线时间"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              show-overflow-tooltip
              min-width="250"
              align="center"
            >
              <template #default="scope">
                <el-button
                  v-if="type.indexOf('详情') > -1"
                  @click="handleClicked(scope.row.id)"
                  type="text"
                  size="small"
                  >查看</el-button
                >
                <el-button
                  v-if="
                    type.indexOf('课程信息') > -1 && scope.row.status !== '上线'
                  "
                  type="text"
                  size="small"
                  @click="goFollowA(scope.row.id)"
                  >课程信息</el-button
                >
                <el-button
                  v-if="
                    type.indexOf('运营信息') > -1 && scope.row.status !== '上线'
                  "
                  type="text"
                  size="small"
                  @click="goFollowE(scope.row.id)"
                  >运营信息</el-button
                >
                <el-button
                  v-if="
                    type.indexOf('审核') > -1 && scope.row.status !== '上线'
                  "
                  @click="handAudit(scope.row.id)"
                  type="text"
                  size="small"
                  >审核</el-button
                >
                <el-button
                  v-if="
                    type.indexOf('上线') > -1 && scope.row.status === '审核通过'
                  "
                  @click="handOnline(scope.row.id)"
                  type="text"
                  size="small"
                  >上线</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <Pagination
            :pageSize="pageSize"
            :currentPage="currentPage"
            :total="total"
            @handSizeChange="handSizeChange"
            @handCurrentChange="handCurrentChange"
          ></Pagination>
          <!-- 分页 -->

          <!-- 审核弹窗 -->
          <el-dialog title="审核" v-model="dialogFormVisible" width="500px">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
            >
              <el-form-item label="审核：" prop="status">
                <el-radio-group v-model="ruleForm.status">
                  <el-radio label="2">审核通过</el-radio>
                  <el-radio label="1">审核不通过</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="备注：">
                <el-input
                  type="textarea"
                  v-model="ruleForm.audit_remarks"
                ></el-input>
              </el-form-item>
            </el-form>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="submitForm('ruleForm')"
                  >确 定</el-button
                >
                <el-button @click="dialogFormVisible = false">取 消</el-button>
              </span>
            </template>
          </el-dialog>
          <!-- 审核弹窗 -->
        </el-tab-pane>
        <el-tab-pane label="课程信息列表" name="2">
          <el-table
            :data="tableArr"
            border
            style="width: 100%"
            v-loading="loadingT"
          >
            <el-table-column prop="id" label="ID" width="80" align="center">
            </el-table-column>
            <el-table-column
              prop="sentence"
              label="跟读英文"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="sentence_name"
              label="跟读中文"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="level_name"
              label="所属阶段"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="program_name"
              label="所属主题"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="updated_at"
              label="最近编辑时间"
              width="300"
              align="center"
            >
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              show-overflow-tooltip
              min-width="250"
            >
              <template #default="scope">
                <el-button
                  v-if="type.indexOf('详情') > -1"
                  @click="handClicked(scope.row.id)"
                  type="text"
                  size="small"
                  >查看</el-button
                >
                <el-button
                  v-if="type.indexOf('课程信息') > -1"
                  type="text"
                  size="small"
                  @click="goFollowA(scope.row.id)"
                  >课程信息</el-button
                >
                <el-button
                  v-if="type.indexOf('运营信息') > -1"
                  type="text"
                  size="small"
                  @click="goFollowE(scope.row.id)"
                  >运营信息</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <Pagination
            :pageSize="pageSizeT"
            :currentPage="currentPageT"
            :total="totalT"
            @handSizeChange="handSizeChangeT"
            @handCurrentChange="handCurrentChangeT"
          ></Pagination>
          <!-- 分页 -->
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination.vue";
import { themeTree } from "@/api/apiList/operate-api";
import {
  getFollowList,
  getClassInfoList,
  auditFollowTask,
  onlineFollowTask,
} from "@/api/apiList/content-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      isForm: true,
      form: {
        page: 1,
        pageSize: 10,
        keyword: "",
        updated_at: "",
        level_id: "",
        program_id: "",
        status: "",
        is_alternative: "",
      },
      formT: {
        page: 1,
        pageSize: 10,
        keyword: "",
        onlinetime: "",
        updated_at: "",
        level_id: "",
        program_id: "",
        status: "",
        is_alternative: "",
      },
      ruleForm: {
        id: "",
        status: "",
        audit_remarks: "",
      },
      rules: {
        status: [
          { required: true, message: "请选择审核结果", trigger: "change" },
        ],
      },
      onlinetime: "",
      updated_at: "",
      info_updated_at: "",
      activeName: "1",
      tableData: [],
      tableArr: [],
      programList: [],
      programLists: [],
      loading: true,
      loadingT: true,
      pageSize: 10,
      currentPage: 1,
      total: 10,
      pageSizeT: 10,
      currentPageT: 1,
      totalT: 10,
      type: "",
      dialogFormVisible: false,
    };
  },
  methods: {
    // 运营信息
    goFollowE(e) {
      this.$router.push({ path: "/follow-edior/" + e });
    },
    // 课程信息
    goFollowA(e) {
      this.$router.push({ path: "/follow-add/" + e });
    },
    // 查看运营信息
    handleClicked(e) {
      this.$router.push({ path: `/follow-look/${e}/1` });
    },
    // 查看课程信息
    handClicked(e) {
      this.$router.push({ path: `/follow-look/${e}/2` });
    },
    onSubmit() {
      this.loadingT = true;
      this.handInfoList();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.info_updated_at = "";
    },
    handSubmit() {
      this.loading = true;
      this.handList();
    },
    handResetForm(formName) {
      this.$refs[formName].resetFields();
      this.onlinetime = "";
      this.updated_at = "";
    },
    handleClick(e) {
      if (e.props.name === "1") {
        this.isForm = true;
      } else {
        this.isForm = false;
      }
    },
    // 创建课程信息
    goFollow() {
      this.$router.push({ path: "/follow-add/0" });
    },
    // 跟读检索主题
    handProgram() {
      this.formT.program_id = "";
      let obj = {
        level_id: this.formT.level_id,
      };
      themeTree(obj).then((res) => {
        if (res.result === "200") {
          this.programList = res.data;
        }
      });
    },
    // 课程检索主题
    handPrograms() {
      this.form.program_id = "";
      let obj = {
        level_id: this.form.level_id,
      };
      themeTree(obj).then((res) => {
        if (res.result === "200") {
          this.programLists = res.data;
        }
      });
    },
    // 上线时间
    handOnlineTime(e) {
      if (e) {
        let m =
          new Date(e).getMonth() + 1 > 9
            ? new Date(e).getMonth() + 1
            : "0" + (new Date(e).getMonth() + 1);
        let d =
          new Date(e).getDate() > 9
            ? new Date(e).getDate()
            : "0" + new Date(e).getDate();
        this.formT.onlinetime = new Date(e).getFullYear() + "-" + m + "-" + d;
      } else {
        this.formT.onlinetime = "";
      }
    },
    // 最近编辑时间
    handUpdate(e) {
      if (e) {
        let m =
          new Date(e).getMonth() + 1 > 9
            ? new Date(e).getMonth() + 1
            : "0" + (new Date(e).getMonth() + 1);
        let d =
          new Date(e).getDate() > 9
            ? new Date(e).getDate()
            : "0" + new Date(e).getDate();
        let h =
          new Date(e).getHours() > 9
            ? new Date(e).getHours()
            : "0" + new Date(e).getHours();
        let f =
          new Date(e).getMinutes() > 9
            ? new Date(e).getMinutes()
            : "0" + new Date(e).getMinutes();
        let s =
          new Date(e).getSeconds() > 9
            ? new Date(e).getSeconds()
            : "0" + new Date(e).getSeconds();
        this.formT.updated_at =
          new Date(e).getFullYear() +
          "-" +
          m +
          "-" +
          d +
          "-" +
          h +
          "-" +
          f +
          "-" +
          s;
      } else {
        this.formT.updated_at = "";
      }
    },
    // 课程编辑时间
    handInfoUpdate(e) {
      if (e) {
        let m =
          new Date(e).getMonth() + 1 > 9
            ? new Date(e).getMonth() + 1
            : "0" + (new Date(e).getMonth() + 1);
        let d =
          new Date(e).getDate() > 9
            ? new Date(e).getDate()
            : "0" + new Date(e).getDate();
        let h =
          new Date(e).getHours() > 9
            ? new Date(e).getHours()
            : "0" + new Date(e).getHours();
        let f =
          new Date(e).getMinutes() > 9
            ? new Date(e).getMinutes()
            : "0" + new Date(e).getMinutes();
        let s =
          new Date(e).getSeconds() > 9
            ? new Date(e).getSeconds()
            : "0" + new Date(e).getSeconds();
        this.form.updated_at =
          new Date(e).getFullYear() +
          "-" +
          m +
          "-" +
          d +
          "-" +
          h +
          "-" +
          f +
          "-" +
          s;
      } else {
        this.form.updated_at = "";
      }
    },
    // 审核弹窗
    handAudit(e) {
      this.ruleForm.id = e;
      this.dialogFormVisible = true;
    },
    // 审核
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          auditFollowTask(this.ruleForm).then((res) => {
            if (res.result === "200") {
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: res.data.info,
              });
              this.handList();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 上线
    handOnline(e) {
      this.$confirm("确定要将此条任务上线吗？上线后用户可进行跟读？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {
            id: e,
          };
          onlineFollowTask(obj).then((res) => {
            if (res.result === "200") {
              this.$message({
                type: "success",
                message: res.data.info,
              });
              this.handList();
            } else if (res.result === "300018") {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消上线",
          });
        });
    },
    handSizeChange(e) {
      this.loading = true;
      this.pageSize = e;
      this.formT.pageSize = e;
      this.handList();
    },
    handCurrentChange(e) {
      this.loading = true;
      this.currentPage = e;
      this.formT.page = e;
      this.handList();
    },
    handSizeChangeT(e) {
      this.loadingT = true;
      this.pageSizeT = e;
      this.form.pageSize = e;
      this.handInfoList();
    },
    handCurrentChangeT(e) {
      this.loadingT = true;
      this.currentPageT = e;
      this.form.page = e;
      this.handInfoList();
    },
    // 跟读任务
    handList() {
      getFollowList(this.formT).then((res) => {
        if (res.result === "200") {
          res.data.data.forEach((item) => {
            switch (item.status) {
              case 0:
                item.status = "待审核";
                break;
              case 1:
                item.status = "审核不通过";
                break;
              case 2:
                item.status = "审核通过";
                break;
              case 3:
                item.status = "上线";
                break;
            }
          });
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
    // 课程信息
    handInfoList() {
      getClassInfoList(this.form).then((res) => {
        if (res.result === "200") {
          this.tableArr = res.data.data;
          this.totalT = res.data.total;
          this.loadingT = false;
        }
      });
    },
  },
  mounted() {
    this.type = this.$store.state.type;
    this.handList();
    this.handInfoList();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "follow_add") {
        vm.activeName = "2";
        vm.isForm = false;
      }
    });
  },
};
</script>

<style lang="scss">
.table-follow {
  position: relative;

  .follow-add {
    position: absolute;
    width: 98px;
    height: 40px;
    right: 30px;
    top: 30px;
    z-index: 1;
  }
}
</style>
